<template>
  <div>
    <WebCheckbox
      v-model="acceptPolicy"
      :value="true"
      name="acceptPolicy"
      :rules="{ required: pageData.isPolicyRequired }"
      class="[&:not(.tw-checkbox--has-error)_.tw-linktext]:text-[#007AFF]"
      :class="inputClass"
      :page-options="pageOptions"
      :disabled="disabled"
      @error="onError"
    >
      <i18n-t
        scope="global"
        keypath="generate.payment.paymentForm.checkAgreement"
        tag="div"
        :style="{
          ...consentStyle,
          color: hasError ? undefined : consentStyle?.color
        }"
        :locale="locale"
        class="font-normal first-letter:uppercase"
      >
        <template #distance>
          <span class="tw-linktext inline cursor-pointer hover:underline" @click.prevent="openDistanceSalesAgreementModal">
            {{ translate('generate.payment.paymentForm.keyword.distance', locale) }}
          </span>
        </template>
        <template #info>
          <span class="tw-linktext inline cursor-pointer hover:underline" @click.prevent="openInformationFormModal">
            {{ translate('generate.payment.paymentForm.keyword.info', locale) }}
          </span>
        </template>
      </i18n-t>
    </WebCheckbox>

    <DistanceSalesAgreementModal
      v-if="showDistanceSalesAgreementModal && !isEditorMode"
      :agreement-data="pageData.agreementData"
      @close="closeDistanceSalesAgreementModal"
    />

    <InformationFormModal
      v-if="informationFormModal && !isEditorMode"
      :agreement-data="pageData.agreementData"
      @close="closeInformationFormModal"
    />
  </div>
</template>


<script setup lang="ts">
import { type PropType, watch, ref } from 'vue';
import type { PageOptions } from '@shared/types/model';
import { useTranslate } from '@shared/composable/useTranslate';
import WebCheckbox from '@shared/components/checkbox/index.vue';
import DistanceSalesAgreementModal from './DistanceSalesAgreementModal/index.vue';
import InformationFormModal from './InformationFormModal/index.vue';

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  locale: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  inputClass: { type: Object, default: () => ({})},
  consentStyle: { type: Object, default: () => ({})},
  modelValue: { type: Boolean, default: false },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false }
})

const { translate } = useTranslate()
const acceptPolicy = ref(props.modelValue);
const showDistanceSalesAgreementModal = ref(false);
const informationFormModal = ref(false);
const hasError = ref(false);

function onError(err: boolean) {
  hasError.value = err;
}

function openDistanceSalesAgreementModal() {
  showDistanceSalesAgreementModal.value = true;
}

function openInformationFormModal() {
  informationFormModal.value = true;
}

function closeDistanceSalesAgreementModal() {
  showDistanceSalesAgreementModal.value = false;
}

function closeInformationFormModal() {
  informationFormModal.value = false;
}

watch(
  () => acceptPolicy.value,
  (val) => emit('update:modelValue', !!val)
);
</script>
